<template>


    <div class="col-10 offset-1 mt-5" >
      <div class="row ">
        <div class="col-12">
          <div class="m-card">
            <p class="o-heading">
              <strong>{{ $t("mdl.footer.title") }}</strong>
            </p>
            <p >
              {{ $t("mdl.footer.headline") }}<strong>{{ $t("mdl.footer.headline_1") }}</strong> {{ $t("mdl.footer.headline_2") }}
            </p>
            <div class="row no-gutters mt-3">
              <div class="col-2 col-md-1">
                <span class="icon-phone o-heading--lg"></span>
              </div>
              <div class="col-10 col-md-11">
                <p>
                  {{ $t("mdl.footer.phone") }}
                  <strong>{{ $t("mdl.footer.phone_data") }}</strong
                  >.
                </p>
              </div>
            </div>
            <div class="row no-gutters" v-if="false">
              <div class="col-2 col-md-1">
                <span class="icon-email o-heading--lg"></span>
              </div>
              <div class="col-10 col-md-11">
                <p>
                  {{ $t("mdl.footer.email") }}
                  <strong>{{ $t("mdl.footer.email_data") }}</strong>
                </p>
              </div>
            </div>
            <div class="row no-gutters">
              <div class="col-2 col-md-1">
                <span class="icon-time o-heading--lg"></span>
              </div>
              <div class="col-10 col-md-11">
                <p>
                  {{ $t("mdl.footer.orario") }}
                </p>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

</template>

<script>
export default {
  name: "AppMdlFooter",
  props: {

  },
  data() {
    return {};
  },
  computed:{

  },

  methods: {},
};
</script>
