<template>
  <div class="col-10 offset-1">
    <div class="m-card text-center ">
      <h1 class="o-heading o-heading--xl">{{ $t(title) }}</h1>
      <p v-if="subtitle!=null">
        {{ $t(subtitle) }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "AppTitle",
  props: {
    title:{
      default:"no title"
    },
    subtitle:{
      default:null
    }
  },
  data() {
    return {};
  },


  methods: {},
};
</script>
