<template>
  <div id="dashboard">
    <div class="row no-gutters">
      <app-title title="mdl.verifica.title"></app-title>
    </div>


    <div class="row no-gutters mt-5" v-if="checked ">
      <div class="col-10 offset-1 col-md-5  offset-md-1 mb-5 pr-sm-0 pr-md-1" v-if="step!==1">
        <h1 class="d-none d-md-flex">{{$t('mdl.verifica.heading1')}}</h1>
        <h3 class="d-flex d-md-none"><strong>{{$t('mdl.verifica.heading1')}}</strong></h3>
        <p>{{$t('mdl.verifica.text1')}}</p>
        <p>{{$t('mdl.verifica.text2')}}</p>
        <p>{{$t('mdl.verifica.text3')}}</p>
      </div>
      <div class="col-10 offset-1 col-md-5  offset-md-0 mt-3 mt-md-0">
        <div class="o-registration-collapse__form" v-if="step==0">
          <b-form-group id="mdl" :label="this.$t('mdl.verifica.mdl')" label-for="mdl-input" class="m-input">
            <b-form-input id="mdl-input" v-model="form_data.mdl" type="text"
                          :placeholder="this.$t('mdl.verifica.mdl_place')" required></b-form-input>
            <b-form-invalid-feedback>
              {{ $t("step.invalid_nome") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group id="email" :label="this.$t('mdl.verifica.email')" label-for="email-input" class="m-input">
            <b-form-input id="email-input" v-model="form_data.email" type="text"
                          :placeholder="this.$t('mdl.verifica.email_place')" required></b-form-input>
            <b-form-invalid-feedback>
              {{ $t("step.invalid_cognome") }}
            </b-form-invalid-feedback>
          </b-form-group>
          <div class="row no-gutters mt-3">
            <div class="col-12 offset-0">
              <b-alert  variant="danger" id="errore_step_1" v-model="errStep1" >{{ $t(this.errStep1_message) }}</b-alert>
            </div>
          </div>
          <div class="o-registration-collapse__action d-none d-md-flex">
            <b-button  @click="enroll()" :disabled="!valid" variant="primary" size="md">{{ $t("mdl.verifica.invia") }}
            </b-button>
            <div class="ml-5">
              <b-link @click="openWhat()">{{$t('mdl.verifica.what')}}</b-link><br>

            </div>
          </div>
          <div class="o-registration-collapse__action d-block d-md-none row">
            <b-button  class="col-10 offset-1 mb-4" @click="enroll()" :disabled="!valid" variant="primary" size="md">{{ $t("mdl.verifica.invia") }}
            </b-button>

              <b-link class="col-10 offset-1 " @click="openWhat()">{{$t('mdl.verifica.what')}}</b-link><br>
              <b-link class="col-10 offset-1" @click="openWhere()">{{$t('mdl.verifica.where')}}</b-link>

          </div>
        </div>

      </div>
      <div class="offset-3 col-6" v-if="step==1">
      <div class="o-registration-collapse__success" >
        <p><strong>{{$t('mdl.verifica.success-heading')}}</strong></p><br>
        <p>{{$t('mdl.verifica.success-text').replace("##email##",maskEmail(this.form_data.email))}}</p>
        <p class="mt-2"><strong>{{$t('mdl.verifica.success-text2')}}</strong></p>
      </div>
      </div>
      <AppMdlFooter  v-if="step!==1"></AppMdlFooter>
    </div>
    <b-modal ref="what-dialog"
             id="whatDlg"
             centered
             modal-class="o-modal"
             content-class="o-modal__content m-mdl-dispo__no-flex"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <p class="o-modal__body--title mt-5 mb-3">
        <strong>{{ $t("mdl.verifica.what")}}</strong>
      </p>
      <p>
        {{$t('mdl.verifica.what_text1')}} <strong>{{$t('mdl.verifica.what_text2')}}</strong> {{$t('mdl.verifica.what_text3')}}
      </p>
      <div class="row mt-3">

        <div class="col-12">
          <b-button variant="outline-primary" size="md" @click="chiudi('what-dialog')">{{ $t('mdl.verifica.indietro') }}</b-button>
        </div>
      </div>

    </b-modal>
    <b-modal ref="where-dialog"
             id="whereDlg"
             centered
             modal-class="o-modal"
             content-class="o-modal__content m-mdl-dispo__no-flex"
             header-class="o-modal__header"
             dialog-class="o-modal__dialog"
             body-class="o-modal__body o-modal__body--response "
             ok-title="ok-title"
             :cancel-disabled="true"
             :hide-footer="true"
             :hide-header-close="true"
             :hide-header="true"
             size="lg"
    >
      <div class="row">
        <div class="col-12">

          <b-button variant="primary" size="md" @click="chiudi('where-dialog')">{{ $t('mdl.verifica.annulla') }}</b-button>
        </div>
      </div>
    </b-modal>
  </div>

</template>

<script>

import apiAxios from "@/libs/apiAxios";
import AppTitle from "@/components/molecules/AppTitle";
import AppMdlFooter from "@/components/molecules/AppMdlFooter";
export default {
  name: "mdl-landing",
  components: {AppMdlFooter, AppTitle},
  props: {},
  data() {
    return {
      step:0,
      form_data:{
        mdl:null,
        email:null
      },
      errStep1:false,
      errStep1_message:null,
      checked: false,
      valid:false,
      hasError: false,
      errorStr:""
    };
  },
  computed:{},
  created() {
    this.checkStatus();
  },
  watch:{
    form_data:{
      handler() {
        this.checkForm();
      },
      deep: true,
    }
  },
  methods: {
    openWhat(){
      this.$refs['what-dialog'].show();
    },
    openWhere(){
      this.$refs["where-dialog"].show();
    },
    chiudi(ref){
      this.$refs[ref].hide();
    },
    checkForm(){
      console.log(this.form_data);
      if(this.form_data.email!=null && this.form_data.mdl!=null)
      {
        this.valid=this.form_data.mdl.length>4 && this.isEmail(this.form_data.email)==null;
      }
    },
    isEmail(email) {
      const regExp = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/i;
      if (email != null) {
        if (regExp.test(email) === false) {
          return false;
        } else {
          return null;
        }
      } else {
        return null;
      }
    },
    checkStatus(){
      apiAxios.get('mdl/status').then(result=>{
        const data=result.data.MdlStatusResult;
        if (data.success)
        {
          if (data.data.enrolled) {
            this.$router.push('/mdl/home');
          }
          else {
            this.checked=true;
          }
        }
        else {
          this.hasError=true;
        }
      })
          .catch(error=>{
            this.errorStr=error;
          })
    },
    enroll(){
      this.errStep1=false;
      const data={
        mdl:this.form_data.mdl,
        email:this.form_data.email,
          urlSuccess:document.location.origin+"/mdl/enroll-esito?esito=success",
        urlFail:document.location.origin+"/mdl/enroll-esito?esito=fail",

      };
      apiAxios.post('mdl/enroll',data).then(result=>{
        const data=result.data.MdlEnrollResult;
        if (data.success)
        {
         this.step=1;
        }
        else {
          this.errStep1=true;
          this.errStep1_message=this.$t('mdl.verifica.errors.'+data.error);
        }
      })
          .catch(error=>{
            this.errorStr=error;
          })
    },
    maskEmail(email){
      if (email==null)
        return null;
      let tok=email.split('@');
      if (tok.length<2)
        return email;
      return tok[0].substring(0,3)+"...@..."+tok[1].slice(-5);
    }
  }
};
</script>
